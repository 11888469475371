import React, { useState } from "react"
import Layout from "../../components/Layout"

const isBrowser = typeof window !== "undefined"

const PrivacyTos = props => {
  const [isScrolled, setIsScrolled] = useState(false)

  if (isBrowser) {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }
  return (
    <Layout isScrolled={isScrolled}>
      <Content />
    </Layout>
  )
}

export default PrivacyTos

const Content = () => (
  <div className="shadow-lg background-linear-2">
    <div className="px-3.5 mx-auto md:px-10 md:pb-32 pb-20 lg:px-24 lg:pb-14 xl:px-40 xl:pb-20">
      <div className="pt-10 text-center text-white title md:pt-24">
        <span className="text-white text-2xl md:text-3.5xl ">
          PRIVACY POLICY
        </span>
      </div>
      <div className="px-3 py-8 mt-6 bg-white rounded-lg md:px-10 text-policy md:py-14 md:mt-16 lg:mt-16 lg:py-14 lg:px-16">
        <div className="text-xl text-justify md:text-base leading-policy md:text-left">
          <h1 className="font-custom-black">Apace Privacy Policy</h1>
          <br />
          <p className="font-bold">
            <i>Last updated: June 29, 2021</i>
          </p>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
          <br />
          <h1 className="font-custom-black">Interpretation and Definitions</h1>
          <br />
          <p>
            <strong className="font-bold">Interpretation</strong>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <strong>Definitions</strong>
            <p>For the purposes of this Privacy Policy:</p>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
            <p>
              <strong>Business</strong>, for the purpose of the CCPA (California
              Consumer Privacy Act), refers to the Company as the legal entity
              that collects Consumers&#39; personal information and determines
              the purposes and means of the processing of Consumers&#39;
              personal information, or on behalf of which such information is
              collected and that alone, or jointly with others, determines the
              purposes and means of the processing of consumers&#39; personal
              information, that does business in the State of California.
            </p>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in thisAgreement) refers to Apace Refunds Inc., 10201 E Bay Harbor
              Dr, Bay HarborIslands.
            </p>
            <p>
              <strong>Consumer</strong>, for the purpose of the CCPA (California
              Consumer Privacy Act), means a natural person who is a California
              resident. A resident, as defined in the law,includes (1) every
              individual who is in the USA for other than a temporary or
              transitory purpose, and (2) every individual who is domiciled in
              the USA who is outside the USA for a temporary or transitory
              purpose.
            </p>
            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>
            <p>
              <strong>Country</strong> refers to: Florida, United States
            </p>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
            <p>
              <strong>Do Not Track</strong> (DNT) is a concept that has been
              promoted by US regulatory authorities, in particular the U.S.
              Federal Trade Commission (FTC), for the Internet industry to
              develop and implement a mechanism for allowing internet users to
              control the tracking of their online activities across websites.
            </p>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.For the purposes of the
              CCPA, Personal Data means any information that identifies,relates
              to, describes or is capable of being associated with, or could
              reasonably be linked, directly or indirectly, with You.
            </p>
            <p>
              <strong>Sale</strong>, for the purpose of the CCPA (California
              Consumer Privacy Act), means selling,renting, releasing,
              disclosing, disseminating, making available, transferring, or
              otherwise communicating orally, in writing, or by electronic or
              other means, aConsumer&#39;s personal information to another
              business or a third party for monetary or other valuable
              consideration.
            </p>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of
              theCompany, to perform services related to the Service or to
              assist the Company in analyzing how the Service is used.
            </p>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of theService or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
            <p>
              <strong>Website</strong> refers to Apace payments, accessible from{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary"
                href="https://www.apacepayments.com"
              >
                Apace Website
              </a>
            </p>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </p>
          <br />
          <h1 className="font-custom-black">
            Collecting and Using Your Personal Data
          </h1>
          <br />
          <p>
            <strong>Types of Data Collected</strong>
            <br />
            <br />
            <strong>Personal Data</strong>
            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
              <ul className="ml-6">
                <li>Email address First name and last name</li>
                <li> Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City </li>
                <li>Debit and/or Credit Card information</li>
                <li> Usage Data</li>
              </ul>
            </p>
            <strong>Usage Data.</strong>
            <p>
              Usage Data is collected automatically when using the Service.Usage
              Data may include information such as Your Device&#39;s Internet
              Protocol address (e.g. IPaddress), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.When You access the Service by or
              through a mobile device, We may collect certain information
              automatically, including, but not limited to, the type of mobile
              device You use,Your mobile device unique ID, the IP address of
              Your mobile device, Your mobile operating system, the type of
              mobile Internet browser You use, unique device identifiers and
              other diagnostic data.We may also collect information that Your
              browser sends whenever You visit our Service or when You access
              the Service by or through a mobile device.
            </p>
            <br />
            <strong>Tracking Technologies and Cookies</strong>
            <br />
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
              <br />
              <br />
              <ul className="list-disc list-inside">
                <li>
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a
                  small file placed on Your Device. You can instruct Your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent.However, if You do not accept Cookies, You may not
                  be able to use some parts of ourService. Unless you have
                  adjusted Your browser setting so that it will refuse
                  Cookies,our Service may use Cookies.
                </li>
                <li>
                  <strong>Flash Cookies.</strong> Certain features of our
                  Service may use local stored objects (or FlashCookies) to
                  collect and store information about Your preferences or Your
                  activity on ourService. Flash Cookies are not managed by the
                  same browser settings as those used forBrowser Cookies. For
                  more information on how You can delete Flash Cookies, please
                  read &quot;Where can I change the settings for disabling, or
                  deleting local shared objects?&quot;available at{" "}
                  <a
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                  >
                    Adobe Flash Player EOL
                  </a>
                </li>
                <li>
                  <strong>Web Beacons.</strong> Certain sections of our Service
                  and our emails may contain small electronic files known as web
                  beacons (also referred to as clear gifs, pixel tags, and
                  single-pixel gifs) that permit the Company, for example, to
                  count users who have visited those pages or opened an email
                  and for other related website statistics (for
                  example,recording the popularity of a certain section and
                  verifying system and server integrity).
                </li>
              </ul>
              <br />
              <p>
                Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                Cookies. Persistent Cookies remain on Your personal computer or
                mobile device when You go offline, while Session Cookies are
                delete das soon as You close Your web browser. You can learn
                more about cookies here: All AboutCookies by TermsFeed.
              </p>
            </p>
            <br />
            <p>
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </p>
            <strong>Necessary / Essential Cookies</strong>
            <p>
              ‍Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
            <strong>‍Cookies Policy / Notice Acceptance Cookies</strong>
            <p>
              ‍Type: Persistent CookiesAdministered by: Us
              <br />
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
            <br />
            <p>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
            <strong>Use of Your Personal Data</strong>
            <p>
              The Company may use Personal Data for the following purposes:‍‍
            </p>
            <p>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </p>
            <p>
              ‍<strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </p>
            <p>
              ‍<strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </p>
            <p>
              ‍<strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </p>
            <p>
              ‍<strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information. ‍
            </p>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </p>
            <p>
              ‍<strong>For business transfers:</strong> We may use Your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of Our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by Us about our Service users is among
              the assets transferred.For other purposes: We may use Your
              information for other purposes, such as data analysis, identifying
              usage trends, determining the effectiveness of our promotional
              campaigns and to evaluate and improve our Service, products,
              services, marketing and your experience.
            </p>
            <br />
            <p>
              We may share Your personal information in the following
              situations:
              <br />
              <br />
              <ul className="list-disc list-inside">
                <li>
                  <strong>With Service Providers:</strong> We may share Your
                  personal information with Service Providers to monitor and
                  analyze the use of our Service, to contact You.
                </li>
                <li>
                  <strong>For business transfers:</strong> We may share or
                  transfer Your personal information in connection with, or
                  during negotiations of, any merger, sale of Company assets,
                  financing, or acquisition of all or a portion of Our business
                  to another company.
                </li>
                <li>
                  <strong>With Affiliates:</strong> We may share Your
                  information with Our affiliates, in which case we will require
                  those affiliates to honor this Privacy Policy. Affiliates
                  include Our parent company and any other subsidiaries, joint
                  venture partners or other companies that We control or that
                  are under common control with Us.{" "}
                  <strong>With business partners:</strong> We may share Your
                  information with Our business partners to offer You certain
                  products, services or promotions.
                </li>
                <li>
                  <strong>With other users:</strong> when You share personal
                  information or otherwise interact in the public areas with
                  other users, such information may be viewed by all users and
                  may be publicly distributed outside.
                </li>
                <li>
                  <strong>With Your consent:</strong> We may disclose Your
                  personal information for any other purpose with Your consent.
                </li>
              </ul>
            </p>
            <br />
            <p>
              <strong>1 • Refund approved </strong>
              <br />
              <br />
              <p>
                You return your item to the store you purchased, and your return
                and refund gets approved with a 5-14 business days refund delay
              </p>
            </p>
            <br />
            <p>
              <strong>2 • Instant Refund </strong>
              <br />
              <br />
              <p>
                In your refund approval email you will find an Apace instant
                refund options, you fill out the required information, and
                Wahoo! your refund is in your bank account
              </p>
            </p>
            <br />
            <p>
              <strong>3 • Use your money </strong>
              <br />
              <br />
              <p>
                You can right away use your refund money, instead of waiting
                5-14 business days
              </p>
            </p>
          </p>
          <br />
          <p>Apace Refunds 2021 ©</p>
        </div>
      </div>
    </div>
  </div>
)
